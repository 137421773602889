/*Web Design & Development by: Jakub "Jacek" Bedynek | kuba.xray@gmail.com*/

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}




